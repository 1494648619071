import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PreviewSectionTitle from './SectionTitle'

// reactjs white 
import dashboard from '../images/demo/dashboard.jpg';
import sales_monitoring from '../images/demo/sales-monitoring.jpg';
import ad_campaign from '../images/demo/crm.jpg';
import event_management from '../images/demo/event-management.jpg';
import help_desk_management from '../images/demo/help-desk-management.jpg';
import forums from '../images/demo/forums.jpg';
import contact_list from '../images/demo/contact-list.jpg';
import scrumbord from '../images/demo/scrumbord.jpg';
import todo from '../images/demo/todo.jpg';
import chat from '../images/demo/chat.jpg';
import invoice_lists from '../images/demo/invoice-lists.jpg';
import inbox from '../images/demo/inbox.jpg';
import event_calendar from '../images/demo/event-calendar.jpg';

//  reactjs dark 
import dark_dashboard from '../images/demo/dark/img1.png';
import dark_sales_monitoring from '../images/demo/dark/img2.png';
import dark_ad_campaign from '../images/demo/dark/img3.png';
import dark_event_management from '../images/demo/dark/img4.png';
import dark_help_desk_management from '../images/demo/dark/img5.png';
import dark_forums from '../images/demo/dark/img6.png';
import dark_contact_list from '../images/demo/dark/img7.png';
import dark_scrumbord from '../images/demo/dark/img8.png';
import dark_todo from '../images/demo/dark/img9.png';
import dark_chat from '../images/demo/dark/img10.png';
import dark_invoice_lists from '../images/demo/dark/img11.png';
import dark_inbox from '../images/demo/dark/img12.png';
import dark_event_calendar from '../images/demo/dark/img13.png';


// html
import html_dashboard from '../images/demo/html/img1.png';
import html_sales_monitoring from '../images/demo/html/img2.png';
import html_ad_campaign from '../images/demo/html/img3.png';
import html_event_management from '../images/demo/html/img4.png';
import html_help_desk_management from '../images/demo/html/img5.png';
import html_forums from '../images/demo/html/forums.jpg';
import html_contact_list from '../images/demo/html/contact-list.jpg';
import html_scrumbord from '../images/demo/html/scrumbord.jpg';
import html_todo from '../images/demo/html/todo.jpg';
import html_chat from '../images/demo/html/chat.jpg';
import html_invoice_lists from '../images/demo/html/invoice-lists.jpg';
import html_inbox from '../images/demo/html/inbox.jpg';
import html_event_calendar from '../images/demo/html/event-calendar.jpg';

// html
import dark_html_dashboard from '../images/demo/dark-html/img1.png';
import dark_html_sales_monitoring from '../images/demo/dark-html/img2.png';
import dark_html_ad_campaign from '../images/demo/dark-html/img3.png';
import dark_html_event_management from '../images/demo/dark-html/img4.png';
import dark_html_help_desk_management from '../images/demo/dark-html/img5.png';
import dark_html_forums from '../images/demo/dark-html/img14.png';
import dark_html_contact_list from '../images/demo/dark-html/img7.png';
import dark_html_scrumbord from '../images/demo/dark-html/img8.png';
import dark_html_todo from '../images/demo/dark-html/img9.png';
import dark_html_chat from '../images/demo/dark-html/img10.png';
import dark_html_invoice_lists from '../images/demo/dark-html/img11.png';
import dark_html_inbox from '../images/demo/dark-html/img12.png';
import dark_html_event_calendar from '../images/demo/dark-html/img13.png';


const demos = [
    { name: 'dashboard', image: dashboard, link: '/dashboard' },
    { name: 'Sales Monitoring', image: sales_monitoring, link: '/sales-monitoring' },
    { name: 'Ad Campaign', image: ad_campaign, link: '/ad-campaign' },
    { name: 'Event Management', image: event_management, link: '/event-management' },
    { name: 'Help Desk Management', image: help_desk_management, link: '/help-desk-management' },
    { name: 'forums', image: forums, link: '/forums' },
    { name: 'contact list', image: contact_list, link: '/contact-list' },
    { name: 'scrumbord', image: scrumbord, link: '/scrumbord' },
    { name: 'todo', image: todo, link: '/todo' },
    { name: 'chat', image: chat, link: '/chat' },
    { name: 'invoice lists', image: invoice_lists, link: '/invoice-lists' },
    { name: 'inbox', image: inbox, link: '/inbox' },
    { name: 'event calendar', image: event_calendar, link: '/event-calendar' },
];

const demos2 = [
    { name: 'dashboard', image: dark_dashboard, link: '/dashboard' },
    { name: 'Sales Monitoring', image: dark_sales_monitoring, link: '/sales-monitoring' },
    { name: 'Ad Campaign', image: dark_ad_campaign, link: '/ad-campaign' },
    { name: 'Event Management', image: dark_event_management, link: '/event-management' },
    { name: 'Help Desk Management', image: dark_help_desk_management, link: '/help-desk-management' },
    { name: 'forums', image: dark_forums, link: '/forums' },
    { name: 'contact list', image: dark_contact_list, link: '/contact-list' },
    { name: 'scrumbord', image: dark_scrumbord, link: '/scrumbord' },
    { name: 'todo', image: dark_todo, link: '/todo' },
    { name: 'chat', image: dark_chat, link: '/chat' },
    { name: 'invoice lists', image: dark_invoice_lists, link: '/invoice-lists' },
    { name: 'inbox', image: dark_inbox, link: '/inbox' },
    { name: 'event calendar', image: dark_event_calendar, link: '/event-calendar' },
];


const demos3 = [
    { name: 'dashboard', image: html_dashboard, link: 'index.html' },
    { name: 'Sales Monitoring', image: html_sales_monitoring, link: 'sales-monitoring.html' },
    { name: 'Ad Campaign', image: html_ad_campaign, link: 'ad-campaign.html' },
    { name: 'Event Management', image: html_event_management, link: 'event-management.html' },
    { name: 'Help Desk Management', image: html_help_desk_management, link: 'help-desk-management.html' },
    { name: 'forums', image: html_forums, link: 'forums.html' },
    { name: 'contact list', image: html_contact_list, link: 'contact-list.html' },
    { name: 'scrumbord', image: html_scrumbord, link: 'scrumbord.html' },
    { name: 'todo', image: html_todo, link: 'todos.html' },
    { name: 'chat', image: html_chat, link: 'chat.html' },
    { name: 'invoice lists', image: html_invoice_lists, link: 'invoice-lists.html' },
    { name: 'inbox', image: html_inbox, link: 'inbox.html' },
    { name: 'event calendar', image: html_event_calendar, link: 'event-calendar.html' },
];

const demos4 = [
    { name: 'dashboard', image: dark_html_dashboard, link: 'index.html' },
    { name: 'Sales Monitoring', image: dark_html_sales_monitoring, link: 'sales-monitoring.html' },
    { name: 'Ad Campaign', image: dark_html_ad_campaign, link: 'ad-campaign.html' },
    { name: 'Crypto currency', image: dark_html_event_management, link: 'event-management.html' },
    { name: 'Analytical', image: dark_html_help_desk_management, link: 'analytical.html' },
    { name: 'forums', image: dark_html_forums, link: 'forums.html' },
    { name: 'contact list', image: dark_html_contact_list, link: 'contact-list.html' },
    { name: 'scrumbord', image: dark_html_scrumbord, link: 'scrumbord.html' },
    { name: 'todo', image: dark_html_todo, link: 'todos.html' },
    { name: 'chat', image: dark_html_chat, link: 'chat.html' },
    { name: 'invoice lists', image: dark_html_invoice_lists, link: 'invoice-lists.html' },
    { name: 'inbox', image: dark_html_inbox, link: 'inbox.html' },
    { name: 'event calendar', image: dark_html_event_calendar, link: 'event-calendar.html' },
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PreviewDemo = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Fragment>
            <div className="previewDemoArea" id="demo">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <PreviewSectionTitle
                                title="Demo"
                                text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
                            />
                        </div>
                        <Tabs className="demoTabMenu" centered value={value} onChange={handleChange}
                            aria-label="simple tabs example">
                            <Tab disableRipple label="light version" {...a11yProps(0)} />
                            <Tab disableRipple label="dark version" {...a11yProps(1)} />
                            <Tab disableRipple label="Html Light version" {...a11yProps(2)} />
                            <Tab disableRipple label="Html Dark version" {...a11yProps(3)} />
                        </Tabs>
                        <div className="col-12">
                            <TabPanel value={value} index={0}>
                                <div className="row">
                                    {demos.map((demo, i) => (
                                        <div key={i} className="col-sm-6 col-12">
                                            <div className="demoWrapper">
                                                <a className="demoInfo" target="_blank"
                                                    href={`https://nimmu.itech-theme.com${demo.link}`}>{demo.name}</a>
                                                <div className="demoImg">
                                                    {demo.new && <span> {demo.new} </span>}
                                                    <img src={demo.image} alt="" />
                                                </div>
                                                <div className="demoContent">
                                                    <h3>{demo.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </TabPanel>
                        </div>
                        <div className="col-12">
                            <TabPanel value={value} index={1}>
                                <div className="row">
                                    {demos2.map((demo, i) => (
                                        <div key={i} className="col-sm-6 col-12">
                                            <div className="demoWrapper">
                                                <a className="demoInfo" target="_blank"
                                                    href={`https://nimmu-dark.itech-theme.com${demo.link}`}>{demo.name}</a>
                                                <div className="demoImg">
                                                    {demo.new && <span> {demo.new} </span>}
                                                    <img src={demo.image} alt="" />
                                                </div>
                                                <div className="demoContent">
                                                    <h3>{demo.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </TabPanel>
                        </div>
                        <div className="col-12">
                            <TabPanel value={value} index={2}>
                                <div className="row">
                                    {demos3.map((demo, i) => (
                                        <div key={i} className="col-sm-6 col-12">
                                            <div className="demoWrapper">
                                                <a className="demoInfo" target="_blank"
                                                    href={`https://nimmu-html.itech-theme.com/nimmu-preview/${demo.link}`}>{demo.name}</a>
                                                <div className="demoImg">
                                                    {demo.new && <span> {demo.new} </span>}
                                                    <img src={demo.image} alt="" />
                                                </div>
                                                <div className="demoContent">
                                                    <h3>{demo.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </TabPanel>
                        </div>
                        <div className="col-12">
                            <TabPanel value={value} index={3}>
                                <div className="row">
                                    {demos4.map((demo, i) => (
                                        <div key={i} className="col-sm-6 col-12">
                                            <div className="demoWrapper">
                                                <a className="demoInfo" target="_blank"
                                                    href={`https://nimmu-html.itech-theme.com/nimmu-dark/${demo.link}`}>{demo.name}</a>
                                                <div className="demoImg">
                                                    {demo.new && <span> {demo.new} </span>}
                                                    <img src={demo.image} alt="" />
                                                </div>
                                                <div className="demoContent">
                                                    <h3>{demo.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default PreviewDemo