import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PreviewPage from '../Preview_page'
import './App.css';


const App = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={PreviewPage} />
            <Route path='/home' component={PreviewPage} />
          </Switch>
      </Router>
      
    </div>
  );
}

export default App;
